import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-page" }
const _hoisted_2 = { class: "left-0 top-0 w-full z-10" }
const _hoisted_3 = { class: "bg-white relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_menu = _resolveComponent("main-menu")!
  const _component_v_title = _resolveComponent("v-title")!
  const _component_big_banner = _resolveComponent("big-banner")!
  const _component_age_tabs = _resolveComponent("age-tabs")!
  const _component_services_list = _resolveComponent("services-list")!
  const _component_fun_services = _resolveComponent("fun-services")!
  const _component_conception = _resolveComponent("conception")!
  const _component_you_tube = _resolveComponent("you-tube")!
  const _component_virtual_tour = _resolveComponent("virtual-tour")!
  const _component_v_numbers = _resolveComponent("v-numbers")!
  const _component_v_map = _resolveComponent("v-map")!
  const _component_sign_button = _resolveComponent("sign-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_main_menu),
        _createVNode(_component_v_title, { class: "top-0" })
      ]),
      _createVNode(_component_big_banner, {
        class: "mt-4",
        value: "/img/2024-06-1.jpg"
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_age_tabs),
        _createVNode(_component_services_list, { class: "mt-14" }),
        _createVNode(_component_fun_services, { class: "mt-14" }),
        _createVNode(_component_conception, { class: "mt-12" }),
        _createVNode(_component_you_tube, { class: "mt-12 pt-12" }),
        _createVNode(_component_virtual_tour, { class: "mt-12" }),
        _createVNode(_component_v_numbers, { class: "mt-6" }),
        _createVNode(_component_v_map)
      ])
    ]),
    _createVNode(_component_sign_button)
  ], 64))
}