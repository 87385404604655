import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f61d59c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "background" }
const _hoisted_2 = { class: "bg-inner" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "right" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = { class: "requisites" }
const _hoisted_7 = ["textContent"]
const _hoisted_8 = { class: "schedule" }
const _hoisted_9 = ["textContent"]
const _hoisted_10 = { class: "flex items-center flex-wrap gap-3 justify-center" }
const _hoisted_11 = ["href", "textContent"]
const _hoisted_12 = ["href", "textContent"]
const _hoisted_13 = { class: "social" }
const _hoisted_14 = ["href"]
const _hoisted_15 = ["href"]
const _hoisted_16 = ["href"]
const _hoisted_17 = ["href"]
const _hoisted_18 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_big_banner = _resolveComponent("big-banner")!
  const _component_full_logo = _resolveComponent("full-logo")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_dzen_logo = _resolveComponent("dzen-logo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_big_banner, { value: "/img/2024-06-2.jpg" })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_full_logo, {
            class: "logo",
            "with-title": ""
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.address, (address) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_v_icon, {
                class: "w-6 h-6 text-blue-600 mt-px mr-1",
                value: _ctx.icons.map
              }, null, 8, ["value"]),
              _createElementVNode("address", {
                textContent: _toDisplayString(address)
              }, null, 8, _hoisted_7)
            ]))
          }), 256)),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_v_icon, {
              class: "w-6 h-6 text-blue-600 mt-px mr-1",
              value: _ctx.icons.calendar
            }, null, 8, ["value"]),
            _createElementVNode("div", {
              textContent: _toDisplayString(_ctx.config.schedule)
            }, null, 8, _hoisted_9)
          ]),
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.phones, (phone) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "flex",
                key: phone.text
              }, [
                _createVNode(_component_v_icon, {
                  class: "w-6 h-6 text-blue-600 mt-px mr-1",
                  value: _ctx.icons.phone
                }, null, 8, ["value"]),
                _createElementVNode("a", {
                  href: `tel:${phone.callable}`,
                  textContent: _toDisplayString(phone.text)
                }, null, 8, _hoisted_11)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.config.emails, (email) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "flex",
                key: email
              }, [
                _createVNode(_component_v_icon, {
                  class: "w-6 h-6 text-blue-600 mt-px mr-1",
                  value: _ctx.icons.email
                }, null, 8, ["value"]),
                _createElementVNode("address", null, [
                  _createElementVNode("a", {
                    href: _ctx.mailto(email),
                    textContent: _toDisplayString(email)
                  }, null, 8, _hoisted_12)
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_13, [
            (_ctx.config.vk)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "text-blue-500",
                  href: _ctx.config.vk,
                  target: "_blank"
                }, [
                  _createVNode(_component_v_icon, {
                    value: _ctx.icons.vk
                  }, null, 8, ["value"])
                ], 8, _hoisted_14))
              : _createCommentVNode("", true),
            (_ctx.config.telegram)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  class: "text-blue-600",
                  href: _ctx.config.telegram,
                  target: "_blank"
                }, [
                  _createVNode(_component_v_icon, {
                    value: _ctx.icons.telegram
                  }, null, 8, ["value"])
                ], 8, _hoisted_15))
              : _createCommentVNode("", true),
            (_ctx.config.insta)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 2,
                  class: "text-orange-700",
                  href: _ctx.config.insta,
                  target: "_blank"
                }, [
                  _createVNode(_component_v_icon, {
                    value: _ctx.icons.insta
                  }, null, 8, ["value"])
                ], 8, _hoisted_16))
              : _createCommentVNode("", true),
            (_ctx.config.youtube)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 3,
                  class: "text-red-600",
                  href: _ctx.config.youtube,
                  target: "_blank"
                }, [
                  _createVNode(_component_v_icon, {
                    value: _ctx.icons.youtube
                  }, null, 8, ["value"])
                ], 8, _hoisted_17))
              : _createCommentVNode("", true),
            (_ctx.config.dzen)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 4,
                  class: "text-black",
                  href: _ctx.config.dzen,
                  target: "_blank",
                  title: "Канал на Дзен"
                }, [
                  _createVNode(_component_dzen_logo)
                ], 8, _hoisted_18))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}